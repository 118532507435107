import {createContext, Dispatch} from "react";
import Order from "./model/order";
import Company from "./model/company";
import {Card} from "./model/card";

export const DrawerContext = createContext<[boolean, Dispatch<boolean>]>(
    [true, () => {}]
);

export const TokenContext = createContext<[String | null, Dispatch<String>]>(
    [null, () => {}]
);

export const OrderContext = createContext<[Order, Dispatch<Order>]>(
    [new Order(), () => {}]
);

export const CompanyContext = createContext<[Company | null, Dispatch<Company>]>(
    [null, () => {}]
);

export const CardContext = createContext<[Card | null, Dispatch<Card>]>(
    [null, () => {}]
);
