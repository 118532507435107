export class CardPerson {
    name: string = "";
    phone: string = "";
    email: string = "";
}

export class Card {
    id!: number;
    nominal!: number;
    balance!: number;
    created!: number;
    activated?: number;
    expiration_date!: number;
    image!: string;
    number!: number;
    full_number!: string;
    message!: string;
    giver!: CardPerson;
    getter!: CardPerson;
}
