import {MuiToolbarCustom, theme} from "../theme";
import {AppBar, Box, IconButton, Typography} from "@mui/material";
import {BurgerClose} from "react-burger-icons";
import React, {useContext} from "react";
import {DrawerContext} from "../context";
import {useNavigate} from "react-router-dom";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

interface TopBarProps {
    main?: boolean,
    disabled?: boolean,
    final?: boolean
}

export function TopBar(
    { main = false, disabled = false, final = false }: TopBarProps
) {
    const navigate = useNavigate();
    const [isDrawerClosed, setIsDrawerClosed] = useContext(DrawerContext);
    return (
        <AppBar
            position="sticky"
            elevation={0}
            sx={{zIndex: theme.zIndex.drawer + 1}}
        >
            <MuiToolbarCustom>
                <Typography
                    component="div"
                    width="100%"
                    align="center"
                    position="absolute"
                    left={0}
                    right={0}
                >
                    Логотип
                </Typography>
                {
                    final ? null : <IconButton
                        disabled={disabled}
                        size="large"
                        edge="start"
                        aria-label="menu"
                        onClick={() => {
                            if (main) {
                                setIsDrawerClosed(!isDrawerClosed);
                            } else {
                                navigate(-1);
                            }
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                            width="2rem"
                            height="2rem"
                        >
                            {
                                main ? <BurgerClose isClosed={!isDrawerClosed} /> :
                                    <ArrowBackIosNewRoundedIcon sx={{color: theme.palette.primary.main}}/>
                            }
                        </Box>
                    </IconButton>
                }
            </MuiToolbarCustom>
        </AppBar>
    );
}
