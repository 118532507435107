import {Nominal} from "./company";
import {CardPerson} from "./card";

export default class Order {
    image: string | null = null;
    nominal: Nominal | null = null;
    wishes: string = "";
    toMyself: boolean = false;
    anonymously: boolean = false;
    sender: CardPerson = new CardPerson();
    receiver: CardPerson = new CardPerson();
    addedImages: string[] = []
}
