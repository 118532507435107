import "./App.css";
import {createMemoryRouter, RouterProvider} from "react-router-dom";
import {WishesPage} from "./pages/wishes";
import {Box, CircularProgress, Container, ThemeProvider, Typography} from "@mui/material";
import {theme} from "./theme";
import {CardContext, CompanyContext, DrawerContext, OrderContext, TokenContext} from "./context";
import React, {useEffect, useState} from "react";
import Order from "./model/order";
import {ContactsPage} from "./pages/contacts";
import {OrderPage} from "./pages/order";
import {ImageCropperPage} from "./pages/image-cropper";
import {MainPage} from "./pages/main";
import Company from "./model/company";
import axios from "axios";
import {Card} from "./model/card";
import {SuccessPage} from "./pages/success";

const router = createMemoryRouter([
    {
        path: "/",
        element: <MainPage />
    },
    {
        path: "/wish",
        element: <WishesPage />
    },
    {
        path: "/contacts",
        element: <ContactsPage />
    },
    {
        path: "/order",
        element: <OrderPage />
    },
    {
        path: "/image_cropper",
        element: <ImageCropperPage />
    },
    {
        path: "/success",
        element: <SuccessPage />
    }
])

// const getParamsFromURI = ( uri: String ) => {
//     // Get everything after the `?`
//     const [ , paramString ] = uri.split( "?" );
//
//     // Return parameters
//     return new URLSearchParams( paramString );
// };

function App() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isDrawerClosed, setIsDrawerClosed] = useState<boolean>(true);
    const hrefSplit = window.location.href.split("/");
    const token_ = hrefSplit.length > 1 ? hrefSplit.slice(-1)[0] : null;
    const [token, setToken] = useState<String | null>(token_);
    const [order, setOrder] = useState<Order>(new Order());
    const [company, setCompany] = useState<Company | null>(null);
    const [card, setCard] = useState<Card | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            const company: Company = (
                await axios.get(`https://api.carddevgroup.ru/api/v1/public/info/${token_}`)
            ).data;
            setCompany(company);
            const card: Card = (
                await axios.get(`https://api.carddevgroup.ru/api/v1/public/cards/info/${token_}`)
            ).data;
            setCard(card);
            if (card.activated !== null) {
                await router.navigate("/success");
            } else {
                const addedImages = []
                if (!company.images.includes(card.image)) {
                    addedImages.push(card.image);
                }
                setOrder({
                    image: card.image,
                    nominal: company.nominals.find((e) => e.value === card.nominal)!,
                    wishes: card.message,
                    toMyself: card.giver.phone === card.getter.phone,
                    anonymously: false,
                    sender: card.giver,
                    receiver: card.getter,
                    addedImages: addedImages
                })
                await router.navigate("/wish");
                await router.navigate("/contacts");
                await router.navigate("/order");
            }
        };
        fetchData()
            .then(() => {})
            .catch(() => {})
            .finally(() => setIsLoading(false));
    }, [token_, setCompany, setCard, setIsLoading]);

    if (token_ == null) {
        return (
            <React.Fragment>
                <Container
                    maxWidth="md"
                    sx={{
                        backgroundColor: theme.palette.background.paper
                    }}
                >
                    <Box
                        sx={{
                            minHeight: "100vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            variant="h3"
                            color={theme.palette.primary.main}
                        >
                            Not found
                        </Typography>
                    </Box>
                </Container>
            </React.Fragment>
        );
    }

    if (isLoading) {
        return (
            <React.Fragment>
                <Container
                    maxWidth="md"
                    sx={{
                        backgroundColor: theme.palette.background.paper
                    }}
                >
                    <Box
                        sx={{
                            minHeight: "100vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CircularProgress />
                    </Box>
                </Container>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Container
                disableGutters
                maxWidth="md"
            >
                <Box
                    minHeight="100vh"
                    bgcolor={theme.palette.background.paper}
                >
                    <DrawerContext.Provider
                        value={[isDrawerClosed, setIsDrawerClosed]}
                    >
                        <TokenContext.Provider
                            value={[token, setToken]}
                        >
                            <OrderContext.Provider
                                value={[order, setOrder]}
                            >
                                <CompanyContext.Provider
                                    value={[company, setCompany]}
                                >
                                    <CardContext.Provider
                                        value={[card, setCard]}
                                    >
                                        <ThemeProvider theme={theme}>
                                            <RouterProvider router={router} />
                                        </ThemeProvider>
                                    </CardContext.Provider>
                                </CompanyContext.Provider>
                            </OrderContext.Provider>
                        </TokenContext.Provider>
                    </DrawerContext.Provider>
                </Box>
            </Container>
        </React.Fragment>
    );
}

export default App;
