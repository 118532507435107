import React, {useContext, useState} from "react";
import Stack from "@mui/system/Stack";
import {TopBar} from "../widgets/top-bar";
import {Box, Snackbar, Typography} from "@mui/material";
import {CardContext} from "../context";
import {theme} from "../theme";
import {OrderRow} from "../widgets/order-row";
import formatPhone from "../utils/phone";
import moment from "moment/moment";

export function SuccessPage() {
    const [card] = useContext(CardContext);
    const [toShowNotification, setToShowNotification] = useState<boolean>(true);

    let burnDate = new Date();
    if (card?.expiration_date !== null) {
        burnDate = new Date(0);
        burnDate.setUTCSeconds(card!.expiration_date);
    }

    return (
        <Box height="100vh">
            <Stack bgcolor={theme.palette.background.paper}>
                <TopBar final />
                <Stack px="2rem">
                    <Box
                        sx={{
                            mt: "1rem",
                            maxWidth: 500,
                            alignSelf: "center"
                        }}
                    >
                        <img
                            src={card?.image!}
                            alt="card"
                            onError={({currentTarget}) => {
                                currentTarget.onerror = null;
                                currentTarget.src = "card_placeholder.png";
                            }}
                            style={{
                                borderRadius: "1rem",
                                aspectRatio: 1.586,
                                maxWidth: "100%"
                            }}
                        />
                    </Box>
                    <Typography
                        mt="1.5rem"
                        variant="h2">
                        ЭЛЕКТРОННАЯ ПОДАРОЧНАЯ КАРТА
                    </Typography>
                    <Box mt="1rem" />
                    {
                        card?.getter.name ? <OrderRow
                            title="Получатель"
                            text={card?.getter.name}
                            mt="0.5rem"
                        /> : null
                    }
                    {
                        card?.getter.phone ? <OrderRow
                            title="На номер"
                            text={formatPhone(card?.getter.phone)}
                            mt="0.5rem"
                        /> : null
                    }
                    {
                        card?.getter.email ? <OrderRow
                            title="E-mail"
                            text={card?.getter.email}
                            mt="0.5rem"
                        /> : null
                    }
                    {
                        card?.message ? <OrderRow
                            title="Текст пожелания"
                            text={card?.message}
                            direction="column"
                            mt="1rem"
                        /> : null
                    }
                    {
                        card?.giver.name || card?.giver.phone || card?.giver.email ? <Box mt="1rem" /> : null
                    }
                    {
                        card?.giver.name ? <OrderRow
                            title="От"
                            text={card?.giver.name}
                            mt="0.5rem"
                        /> : null
                    }
                    {
                        card?.giver.phone ? <OrderRow
                            title="Телефон"
                            text={formatPhone(card?.giver.phone)}
                            mt="0.5rem"
                        /> : null
                    }
                    {
                        card?.giver.email ? <OrderRow
                            title="E-mail"
                            text={card?.giver.email}
                            mt="0.5rem"
                        /> : null
                    }
                    <Box mt="1rem" />
                    <OrderRow
                        title="Срок действия"
                        text={moment(burnDate).format('DD.MM.YYYY')}
                        mt="0.5rem"
                    />
                    <OrderRow
                        title="Количество"
                        text="+1"
                        mt="0.5rem"
                    />
                    <OrderRow
                        title="Номинал"
                        text={`${card?.nominal.toString()} ₽`}
                        mt="0.5rem"
                    />
                </Stack>
            </Stack>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={toShowNotification}
                autoHideDuration={3000}
                onClose={() => setToShowNotification(false)}
                message="Карта успешно оплачена"
            />
        </Box>
    );
}
