import React, {useContext, useState} from "react";
import Stack from "@mui/system/Stack";
import {TopBar} from "../widgets/top-bar";
import {Box, Typography} from "@mui/material";
import {OrderContext, TokenContext} from "../context";
import {MuiLoadingButtonCustom, theme} from "../theme";
import AddIcon from "@mui/icons-material/Add";
import {OrderRow} from "../widgets/order-row";
import axios from "axios";
import moment from "moment";
import formatPhone from "../utils/phone";

export function OrderPage() {
    const [order] = useContext(OrderContext);
    const [token] = useContext(TokenContext);
    const [loading, setLoading] = useState<boolean>();

    function imageToBase64(blob: Blob): Promise<string> {
        let reader: FileReader = new FileReader();

        return new Promise((resolve, reject) => {
            reader.onloadend = () => {
                resolve(reader.result as string);
            }
            reader.onerror = () => {
                reject("Error reading file.");
            }
            reader.readAsDataURL(blob);
        })
    }

    const onOrder = async () => {
        setLoading(true);
        let image = order.image;
        if (image === null) {
            setLoading(false);
            return;
        }
        if ((new URL(order.image!)).protocol === "blob:") {
            try {
                const blob = await (await fetch(order.image!)).blob();
                image = await imageToBase64(blob);
                image = image.substring(image.indexOf(',') + 1)
            } catch (_) {
                setLoading(false);
                return;
            }
        }
        axios.post(
            `https://api.carddevgroup.ru/api/v1/public/cards/${token}`,
            {
                nominal_id: order.nominal?.id,
                message: order.wishes,
                image: image,
                getter: {
                    name: order.toMyself ? order.sender.name : order.receiver.name,
                    phone: order.toMyself ? order.sender.phone : order.receiver.phone,
                    email: order.toMyself ? order.sender.email : order.receiver.email
                },
                giver: {
                    name: order.sender.name,
                    phone: order.sender.phone ? order.sender.phone : "",
                    email: order.sender.email
                }
            }
        )
            .then((r) => {
                window.location.href = r.data.url;
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            });
    };

    const burnDate = new Date();
    const validityPeriod = order?.nominal?.validity_period;
    if (validityPeriod !== null) {
        burnDate.setDate(burnDate.getDate() + validityPeriod!);
    }

    return (
        <Box height="100vh">
            <Stack bgcolor={theme.palette.background.paper}>
                <TopBar
                    disabled={loading}
                />
                <Stack px="2rem">
                    <Box
                        sx={{
                            mt: "1rem",
                            maxWidth: 500,
                            alignSelf: "center"
                        }}
                    >
                        <img
                            src={order.image!}
                            alt="card"
                            onError={({currentTarget}) => {
                                currentTarget.onerror = null;
                                currentTarget.src = "card_placeholder.png";
                            }}
                            style={{
                                borderRadius: "1rem",
                                aspectRatio: 1.586,
                                maxWidth: "100%"
                            }}
                        />
                    </Box>
                    <Typography
                        mt="1.5rem"
                        variant="h2">
                        ЭЛЕКТРОННАЯ ПОДАРОЧНАЯ КАРТА
                    </Typography>
                    <Box mt="1rem"/>
                    {
                        order.receiver.name ? <OrderRow
                            title="Получатель"
                            text={order.receiver.name}
                            mt="0.5rem"
                        /> : null
                    }
                    <OrderRow
                        title="На номер"
                        text={formatPhone(order.receiver.phone)}
                        mt="0.5rem"
                    />
                    {
                        order.receiver.email ? <OrderRow
                            title="E-mail"
                            text={order.receiver.email}
                            mt="0.5rem"
                        /> : null
                    }
                    {
                        order.wishes ? <OrderRow
                            title="Текст пожелания"
                            text={order.wishes}
                            direction="column"
                            mt="1rem"
                        /> : null
                    }
                    {
                        order.sender.name || order.sender.phone || order.sender.email ? <Box mt="1rem" /> : null
                    }
                    {
                        order.sender.name ? <OrderRow
                            title="От"
                            text={order.sender.name}
                            mt="0.5rem"
                        /> : null
                    }
                    {
                        order.sender.phone ? <OrderRow
                            title="Телефон"
                            text={formatPhone(order.sender.phone)}
                            mt="0.5rem"
                        /> : null
                    }
                    {
                        order.sender.email ? <OrderRow
                            title="E-mail"
                            text={order.sender.email}
                            mt="0.5rem"
                        /> : null
                    }
                    <Box mt="1rem" />
                    <OrderRow
                        title="Срок действия"
                        text={moment(burnDate).format('DD.MM.YYYY')}
                        mt="0.5rem"
                    />
                    <OrderRow
                        title="Количество"
                        text="1"
                        mt="0.5rem"
                    />
                    <OrderRow
                        title="Номинал"
                        text={`${order.nominal?.value.toString()} ₽`}
                        mt="0.5rem"
                    />
                    <OrderRow
                        title="Сумма к оплате"
                        text={`${order.nominal?.cost.toString()} ₽`}
                        mt="0.5rem"
                        mb="5rem"
                    />
                </Stack>
            </Stack>
            <Box
                maxWidth="md"
                position="fixed"
                bottom="0%"
                width="100%"
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <MuiLoadingButtonCustom
                    loading={loading}
                    loadingPosition="start"
                    variant="contained"
                    startIcon={<AddIcon/>}
                    onClick={onOrder}
                    sx={{
                        mb: "1rem",
                        mt: "0.5rem"
                    }}
                >
                Оплатить
                </MuiLoadingButtonCustom>
            </Box>
        </Box>
    );
}
